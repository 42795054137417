//Dependencies
import { useState, useEffect, useCallback, useContext, useMemo, createContext } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';

//Providers
import { useAuthentication } from '../providers/authentication';

//Chakra
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

//Helpers
import { auth, db, setFBUser } from '../helpers/firebase';



const UserContext = createContext();

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}

function UserProvider(props) {
  const {authentication, setIsLoading} = useAuthentication();
  const [alert, setAlert] = useState(false);
  const [user, setUser] = useState(false);

  const updateFBUserPref = useMemo(() => ({
    queryToggle: (value) => {
      if(authentication?.uid) setFBUser(authentication.uid, {queryToggle: value});
    },
    fullScreenTargets: (value) => {
      if(authentication?.uid) setFBUser(authentication.uid, {fullScreenTargets: value});
    }
  }), [authentication.uid]);

  useEffect(() => {
    //Set observer on user snapshot of data
    let unsubscribe = () => console.debug('Loading user...');

    if(authentication) {
      unsubscribe = onSnapshot(doc(db, 'users', authentication.uid),
      (snapshot) => {
        const user = snapshot.data();
        try {
          if(user) { //If logged in then get user from firestore and save to state
            console.debug('Updated user to', user);
            setUser(user);
            setAlert(false);
            setIsLoading(false);
          } else { //If not user returned, then throw error message
            signOut(auth);
            throw new Error('User did not exist in database. Returned to login.');
          }
        } catch (error) {
          setAlert(error);
        }
      },
      (error) => {
        console.error(`Attempted getUser, Firestore returned ${error.code}.`);
        setAlert(error);
      });
    }
    
    //Clean up after render
    return () => unsubscribe();
  }, [authentication, setIsLoading]);

  const value = useMemo(() => ({
    user,
    setUser,
    updateFBUserPref
  }), [user, updateFBUserPref]);

  return (
    <UserContext.Provider value={value} {...props}>
      {alert && 
        <Alert status='error' zIndex={'popover'}>
          <AlertIcon />
          <AlertTitle>{alert.message}</AlertTitle>
          <AlertDescription>Please try again. Contact support if the problem persists.</AlertDescription>
        </Alert>
      }
      {props.children}
    </UserContext.Provider>
  )
}

export {UserProvider, useUser};
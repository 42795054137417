// Dependencies
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
  createContext,
  useReducer
} from 'react';
import uniqid from 'uniqid';
import isEqual from 'lodash.isequal';

// Providers
import { useWaveFinder } from '../wave-finder';
import { useAuthentication } from "../authentication";
import { useUser } from '../user';

// Helpers
import { setFBUser } from "../../helpers/firebase";


/**
 * Helper function takes wave query objects and converts them into state arrays for the user interface
 * @param {*} wfQuery Query object that is used with Wave Finder
 * @returns UI State object ready for Query Builder
 */
const convertQueryToBuilderState = (wfQuery) => {
  const {name, query} = wfQuery;
  const newSetup = Object.entries(query).map(([cKey, cValue]) => {
    return ({
      "name": cKey,
      "id": createId(prefixCont, name, `-${formatString(cKey)}`),
      "attributes": Object.entries(cValue).map(([aKey, aValue]) => {
        return ({
          "id": createId(prefixAttr, name, `-${formatString(aKey)}`),
          "name": aKey,
          "values": aValue,
        })
      }),
    });
  });

  return ({
    name: name,
    setup: newSetup
  });
}

/**
 * Helper function takes state arrays of objects and converts them into a multidimensional object that the Wave Finder can use
 * @param {*} builderState 
 * @returns Multidimensional array ready to pass to Wave Finder
 */
const convertBuilderStateToQuery = (builderState) => {
  const isNotEmpty = (obj) => {
    return Array.isArray(obj) ? obj.length > 0 : 
          (typeof obj === 'object' && Object.keys(obj).length > 0);
  }
  const newQuery = {};

  if(!builderState) return ({}); 

  builderState.filter(context => context.name).forEach(context => {
    if(context.name) {
      newQuery[context.name] = {};
      if(context.attributes) {
        context.attributes.forEach(attribute => {
          if(attribute.name) {
            if(attribute.values && isNotEmpty(attribute.values)) {
              newQuery[context.name][attribute.name] = attribute.values;
            }
          }
        });
      }
    }
  });
  
  return newQuery;
}

const BuilderContext = createContext();

// Create an Id based on a prefix, title string and a key value
function createId(prefix, string, key) {
  const formattedString = string.trim().toLowerCase().replace(/\s+/g, '-');
  return `${prefix}${formattedString}${key ? key : '-' + uniqid()}`;
}

// Formatting helper function for keys in attribute names
function formatString(input) {
  const newStr = input
      .toLowerCase()                   // Convert to lowercase
      .replace(/\s+/g, '-')            // Replace spaces with dashes
      .replace(/[^a-z-]/g, '');       // Remove non-letters
  return newStr;
}

function removeLastSection(id) {
  return id.split('-').slice(0, -1).join('-');
}

// Default values for when query is reset
const prefixCont = 'c-';
const prefixAttr = 'a-';

// Default state for if builder encounters no saved user ui state (query from Firebase)
const defaultBuilderState = {
  name: null,
  setup: [
    {
      attributes: [],
      name: "wave",
      id: "c-default-wave",
    }
  ],
  initialized: false,
};

// Query Reducer setups all functionality for controlling UI state object
// !!! MAKE SURE ALL RETURN VALUES ARE NOW {NAME, SETUP} !!!
function builderStateReducer(state = {name: null, setup: null}, action) {
  switch(action.type) {
    case 'UPDATE_NAME': {
      return ({
        ...state, 
        name: action.value
      });
    }
    case 'ADD_CONTEXT': {
      const newSetup = [...state.setup, {
        id: createId(prefixCont, state.name), // We'll add the context name to this Id once it's selected later
        name: action.value,
        attributes: [{id: createId(prefixAttr, state.name), name: null, values: null}]
      }];

      return ({
        ...state, 
        setup: newSetup
      });
    }
    case 'UPDATE_CONTEXT': {
      const newSetup = state.setup.map(context => {
        if(context.id === action.id) {
          context.id = removeLastSection(context.id) + '-' + formatString(action.value); // lowercase and replace spaces
          context.name = action.value;
          return context;
        } else {
          return context;
        }
      });

      return ({
        ...state,
        setup: newSetup
      });
    }
    case 'DELETE_CONTEXT': {
      const newSetup = state.setup.filter(context => context.id !== action.id);

      return ({
        ...state,
        setup: newSetup
      });
    }
    case 'CLEAR_STATE': {
      return ({
        ...state,
        name: action.name,
        setup: []
      });
    }
    case 'REPLACE_STATE': {
      return action.value;
    }
    case 'ADD_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = state.setup.findIndex(context => {
        return context.id === action.id
      });
      //Add a new default attribute to the attributes array for the context
      const updatedContextWithNewAttrArray = {...state.setup[currContextIndex], attributes: [...state.setup[currContextIndex].attributes, {id: createId(prefixAttr, state.name), name: null, values: null, init: false}]};

      //Return a new array with the updated context
      return ({
        ...state,
        setup: [
          ...state.setup.slice(0, currContextIndex),
          updatedContextWithNewAttrArray,
          ...state.setup.slice(currContextIndex + 1)
        ]
      });
    }

    /**
     * Action properties: contextId, id, name, values
    */ 
    case 'UPDATE_ATTRIBUTE': {
      const newSetup = [...state.setup].map(context => {
        if(context.id === action.contextId) {

          //If matching context then update attributes with new attributes array containing updated value
          context.attributes = [...context.attributes].map(attribute => {

            if(attribute.id === action.id) {
              if(!attribute.init) {
                attribute.id = removeLastSection(attribute.id) + '-' + formatString(action.name ? action.name : attribute.name); // Update empty name and format with lowercase and replace spaces
                attribute.init = true;
              }
              
              //If matching attribute then update name and return
              attribute.name = action.name ? action.name : attribute.name;
              attribute.values = action.values ? action.values : attribute.values;

              return attribute;
            } else {
              return attribute;
            }
          });
          return context;
        } else {
          return context;
        }
      });

      return ({
        ...state,
        setup: newSetup
      });
    }
    case 'DELETE_ATTRIBUTE': {
      //Get the index of the context we're going to update
      const currContextIndex = state.setup.findIndex(context => context.id === action.contextId);
      //Find attributes for correct context using index and copy current attributes minus the deleted one
      const updatedContextAttributes = {...state.setup[currContextIndex], attributes: [...state.setup[currContextIndex].attributes].filter(attribute => attribute.id !== action.id)}
      //Return a new array with the updated context
      return ({
        ...state,
        setup: [
          ...state.setup.slice(0, currContextIndex),
          updatedContextAttributes,
          ...state.setup.slice(currContextIndex + 1)
        ]
      });
    }
    default: {
      console.error(`Unsupported action type: ${action.type}`);
      return state;
    }
  }
}

function BuilderProvider(props) {
  const {user} = useUser();
  const {authentication} = useAuthentication();
  const {wfActive, queryInfo, setWfQuery, builderStateReady, setBuilderStateReady, setIsLoadingWaves} = useWaveFinder();
  
  // Grab attribute info from query info and format it to use for input generation
  const fields = useMemo(() => {
    if(queryInfo) {
      return Object.entries(queryInfo.attributes).map(([key, value]) => {
        if(value.type === 'list') {
          return {name: key, type: value.type, options: value.values}
        }
        if(value.type === 'range') {
          return {name: key, type: value.type, units: value.units, min: value.min, max: value.max}
        }
        console.error('Unknown attribute type specified in query information.');
        return {name: key, type: 'unknown'};
      });
    }
    return null;
  }, [queryInfo]);

  // Grab context wave labels for use in builder
  const contextWaves = useMemo(() => queryInfo ? queryInfo.waves : null, [queryInfo]);

  // On initial render will verify that state array has only valid values
  const initBuilder = useCallback((initState) => {
    let initName = null;
    let initSetup;

    // Check for old query field and convert if necessary
    if(Array.isArray(initState)) {
      initSetup = initState;
    } else {
      const {name, setup} = initState;
      initName = name;
      initSetup = setup;
    }

    console.debug('Retrieved setup from user...', initName, initSetup);
    if(initSetup && fields && contextWaves) {
      // Setup reference values to check against
      const waveRef = contextWaves;
      const fieldsRef = fields;
      const valuesListRef = fields.filter(field => field.type === 'list');
      
      // Check all context waves to ensure their of the correct type
      let checkedState = initSetup.filter(context => waveRef.some(ref => ref === context.name));
      
      // Check all attributes in validated context wave to make sure they're a correct type
      checkedState = [...checkedState].map(context => ({
        ...context,
        attributes: context.attributes.filter(attr => fieldsRef.some(ref => ref.name === attr.name)),
      }));
  
      // Check all the values inside validated attrs to make sure they're allowed as well
      checkedState = [...checkedState].map(context => {
        context.attributes = context.attributes.map(attribute => {
          if(Array.isArray(attribute.values)) { // If array of values, filter invalid values out and return the remaining
            attribute.values = attribute.values.filter(value => {
              const currListRef = valuesListRef.filter(ref => attribute.name === ref.name).pop(); // Get the correct ref values for list type
              return currListRef.options.some(ref => ref === value);
            });
          }
          return attribute;
        });
        return context;
      });
      console.debug('Verified state...', {"name": initName, "setup": initSetup});
      return ({"name": initName, "setup": checkedState});
    }
    
    // If required data is ready or missing then return default builder state
    console.error('User query not validated because of error with reference and/or retrieving user query.');
    return defaultBuilderState;
  }, [fields, contextWaves]);

  // Reducer controls all the state actions for updating the query builder itself
  const initialState = user?.querySetup ? user.querySetup : user?.query;

  const [builderState, dispatch] = useReducer(
    builderStateReducer,
    initialState,
    initBuilder
  ); // Reducer will turn query into usable builder state

  // Saves builder setup to Firebase user
  const saveBuilderStateToFBUser = useCallback(async () => {
    if(builderState) {
      const {name, setup} = builderState; // Make sure we're only saving the name and setup to Firebase database
      
      try {
        console.log('Pushing new builder state to Firestore...');
        await setFBUser(authentication.uid, { querySetup: {name, setup}});
      } catch (e) {
        return console.error(e);
      }
    }
  }, [builderState, authentication?.uid]);

  // When builder state is changed, we need to update the Wave Finder query object and set flag to allow Wave Finder to find waves
  useEffect(() => {
    if(builderState) {
      const newQuery = convertBuilderStateToQuery(builderState.setup);
      wfActive ? setWfQuery(newQuery) : setWfQuery({});
      setBuilderStateReady(true); // signal to wavefinder that UI is ready
    }
  }, [builderState, wfActive, setWfQuery, setBuilderStateReady, setIsLoadingWaves]);

  // If builder state is loaded and user and builder state are different (a UI change occurred),
  // then we save the state to Firebase
  useEffect(() => {
    if(builderState && builderStateReady) {
      saveBuilderStateToFBUser();
    }
  }, [builderStateReady, builderState, saveBuilderStateToFBUser]);

  const value = useMemo(() => ({
    builderState,
    dispatch,
    fields,
    contextWaves,
    saveBuilderStateToFBUser
  }), [builderState, fields, contextWaves, saveBuilderStateToFBUser]);

  return <BuilderContext.Provider value={value} {...props} />
}

function useBuilder() {
  const context = useContext(BuilderContext);
  if (!context) {
    throw new Error(`useBuilder must be used within a BuilderProvider`);
  }
  const {dispatch} = context;

  const actions = useMemo(() => {
    const updateName = (value = null) => dispatch({
      type: 'UPDATE_NAME',
      value: value
    });

    const addContext = (value = null) => dispatch({
      type: 'ADD_CONTEXT',
      value: value
    });
  
    const updateContext = (id, value) => dispatch({
      type: 'UPDATE_CONTEXT',
      id: id,
      value: value
    });
  
    const deleteContext = (id) => dispatch({
      type: 'DELETE_CONTEXT',
      id: id
    });
  
    const addAttribute = (id) => dispatch({
      type: 'ADD_ATTRIBUTE',
      id: id
    });
  
    const updateAttribute = (contextId, id, name = null, values = null) => dispatch({
      type: 'UPDATE_ATTRIBUTE',
      contextId: contextId,
      id: id,
      name: name,
      values: values
    });
  
    const deleteAttribute = (contextId, id) => dispatch({
      type: 'DELETE_ATTRIBUTE',
      contextId: contextId,
      id: id
    });
  
    const clearBuilderState = (name) => dispatch({
      type: 'CLEAR_STATE',
      name: name,
    });
    
    const replaceBuilderState = (value) => dispatch({
      type: 'REPLACE_STATE',
      value: value
    });

    return ({
      updateName,
      addContext,
      updateContext,
      deleteContext,
      addAttribute,
      updateAttribute,
      deleteAttribute,
      replaceBuilderState,
      clearBuilderState,
    })
  }, [dispatch]);

  return {
    actions,
    ...context,
  }
}

export {
  BuilderProvider,
  useBuilder,
  convertQueryToBuilderState,
  convertBuilderStateToQuery
};
//Dependencies
import { useState, useEffect, useContext, useMemo, createContext } from 'react';

//Providers
import { useUser } from '../providers/user';


const ViewContext = createContext();
const defaultItemsPerPage = 10;

function useView() {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error(`useView must be used within a ViewProvider`);
  }

  return context;
}

function ViewProvider(props) {
  const {user, updateFBUserPref} = useUser();
  const [containerRef, setContainerRef] = useState(null); // Container of charts page for back to top targeting
  const [activeTags, setActiveTags] = useState([]); // Holds tag elements that describe filters on total chart results
  const [sort, setSort] = useState(null);  // Determine sort order of results 
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage); // Number of results per page on view
  const [activeGridView, setActiveGridView] = useState({view: null, size: null, typeId: null}); // Current active grid view
  const [fullscreen, setFullscreen] = useState(false); // Monitors when fullscreen is active
  const [hasFullScreenTargets, setHasFullScreenTargets] = useState(null); // Monitors when fullscreen is active
  const [targetsAvailable, setTargetsAvailable] = useState(null); // Determines if targets are available
  const [isLoadingChartViews, setIsLoadingChartViews] = useState(true);

  // Wait for user then set target value
  useEffect(() => {
    if(user?.fullScreenTargets) {
      setHasFullScreenTargets(user.fullScreenTargets);
    }
  }, [user?.fullScreenTargets]);

  // Update user preferences in Firebase if valid value
  useEffect(() => {
    if(hasFullScreenTargets || hasFullScreenTargets === false) {
      updateFBUserPref.fullScreenTargets(hasFullScreenTargets);
    }
  }, [hasFullScreenTargets, updateFBUserPref]);
  
  const value = useMemo(() => ({
    containerRef, setContainerRef,
    activeTags, setActiveTags,
    sort, setSort,
    itemsPerPage, setItemsPerPage,
    activeGridView, setActiveGridView,
    isLoadingChartViews, setIsLoadingChartViews,
    fullscreen, setFullscreen,
    hasFullScreenTargets, setHasFullScreenTargets,
    targetsAvailable, setTargetsAvailable,
    defaultItemsPerPage
  }), [containerRef, activeTags, sort, itemsPerPage, fullscreen, hasFullScreenTargets, activeGridView, isLoadingChartViews, targetsAvailable]);
  
  return <ViewContext.Provider value={value} {...props} />
}

export {ViewProvider, useView};
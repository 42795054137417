const createMarketURLTokenized = (token = null, zoom = 0) => `${process.env.REACT_APP_ASSETS_PATH}/${token}-z${zoom}.svg`;
const createMarketURLBinaryTokenized = (token = null, userPackage = null) => `${process.env.REACT_APP_ASSETS_PATH}/${userPackage}/${token}.bch`;

const getUniqueKeyValues = (array, key) => {
  if(!array || !key) {
    return console.error('Incorrect values supplied.');
  } else {
    return [...new Set( array.flatMap( entry => entry[key] ) )];
  }
  
}

const getMarketBySymbol = (array, symbol) => {
  const market = array.filter( (entry) => entry.Symbol === symbol );
  return market.pop();
}

const getMatchedMarketEntries = (markets, keys, searchQuery) => {
  // Convert search query to lowercase for case-insensitive comparison
  const query = searchQuery.toLowerCase();

  // Filter markets based on search query and keys
  const results = markets.filter(market => {
      // Loop through each key in the keys array
      for (let key of keys) {
          // Check if the key exists in the market object and if its value contains the search query
          if (market[key] && market[key].toString().toLowerCase().includes(query)) {
              return true; // Return true if there's a match
          }
      }
      return false; // Return false if no match is found in any key
  });

  return results; // Return the filtered array of markets
}

// const getMatchedMarketEntries = (array, keys, string) => {
//   const filter = (string, filterBy) => {
//     return filterBy.test(string.toLowerCase());
//   };

//   const filterBy = new RegExp(`${string.toLowerCase().replace(/\$/g, '\\$')}`, 'g'); //create reg expression used for matching

//   const entriesSet = new Set();

//   array.forEach(entry => {
//     let matches = false;
//     keys.forEach(key => {
//       let item = entry[key];
//       if (item && item.length !== 0) {
//         if (typeof item === 'string') {
//           if (filter(item, filterBy)) {
//             matches = true;
//           }
//         } else {
//           let termMatches = item.some(term => filter(term, filterBy));
//           if (termMatches) {
//             matches = true;
//           }
//         }
//       }
//     });
//     if (matches) {
//       entriesSet.add(entry);
//     }
//   });

//   return Array.from(entriesSet);
// };

const marketsFilterBy = (array, key, string) => {
  const results = array?.filter( entry => {
    let filterBy = new RegExp(`^${string.toLowerCase()}$`, "g");
    let item = entry[key];

    if(item) {
      if(typeof item === 'string') {
        let filtering = item.toLowerCase();
    
        return filterBy.test(filtering);
      } else {
        return item.forEach((term) => {
          let filtering = term.toLowerCase();
          
          return filterBy.test(filtering);
        });
      }
    }
    return null;
  } );

  return results;
}

const formatEncodeURI = (keyword) => {
  return encodeURIComponent(keyword);
}

const formatDecodeURI = (keyword) => {
  return decodeURIComponent(keyword);
}

const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

const groupByMarketTags = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property];
    console.log(key);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {})
}

//Get Markets by matching against Waves array returned by WaveFinder
const getMarketsByWaves = (markets = [], waves = []) => {
  const marketsByWaves = waves.map( wave => ({...markets[wave?.market]}));
  return marketsByWaves;
}

//Get a Market with COMPLETE data by matching against Waves array returned by WaveFinder
const getMarketByWaves = (market = {}, waves = []) => {
  let marketByWaves = [];
  if(waves && waves.length) {
    marketByWaves = waves.filter(wave => wave?.market === market?.Id).pop();
  }
  return marketByWaves;
}

//Make a string Title Case
const toTitleCase = (str) => {
  if(str && typeof str == "string") {
    let fLetter = [...str].shift().toUpperCase();
    let remaining = [...str].slice(1).join('');
    return fLetter+remaining;
  } else {
    console.error('Invalid string value passed.');
    return '';
  }
}

export {
  createMarketURLTokenized,
  createMarketURLBinaryTokenized,
  getUniqueKeyValues,
  getMarketBySymbol,
  formatEncodeURI,
  formatDecodeURI,
  marketsFilterBy,
  getMatchedMarketEntries,
  groupBy,
  groupByMarketTags,
  getMarketsByWaves,
  getMarketByWaves,
  toTitleCase
};
// Dependencies
import { useCallback } from "react";
import {
  Routes,
  Route
} from "react-router-dom";

// Providers
import { useAuthentication } from "../providers/authentication";
import { MarketsProvider } from '../providers/markets';
import { ChartProvider } from "../providers/chart";
import { WaveFinderProvider } from "../providers/wave-finder";
import { ViewProvider } from "../providers/view";

// Pages
import {
  Layout,
  Home,
  Charts,
  NoPage,
  NoMarket,
  Login,
  AccountSetup,
  Reset
} from '../pages';

// Components
import { Loader } from '../components';

// Views
import Single from '../views/single';
import { useUser } from "../providers/user";


export default function RoutesHandler() {
  const {isLoading} = useAuthentication();
  const {user} = useUser();

  const loading = useCallback((component) => {
    if(isLoading) return <Loader />;
    return component;
  }, [isLoading]);

  return (
    <>
      <MarketsProvider>
        <ViewProvider>
          {user &&
            <WaveFinderProvider>
              <Routes>
                <Route element={loading(<Layout />)}>
                  <Route path='/' breadcrumb={'Home'} element={loading(<Home />)} />
                  <Route path='/charts' breadcrumb={'Charts'} element={loading(<Charts />)}>
                    <Route path=':market' element={loading(<ChartProvider><Single /></ChartProvider>)} />
                  </Route>
                  <Route path="/market-error" element={<NoMarket />} />
                </Route>
                <Route path='/login' element={<Login />} />
                <Route path='/reset' element={<Reset />} />
                <Route path='/account-setup' element={<AccountSetup />} />
                <Route path="*" element={loading(<NoPage />)} />
              </Routes>
            </WaveFinderProvider>
          }
        </ViewProvider>
      </MarketsProvider>
    </>
  )
}
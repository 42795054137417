import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

function useDebounce(value, delay, options = {}) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Create a debounced function with the provided options
    const handler = debounce((newValue) => {
      setDebouncedValue(newValue);
    }, delay, options);

    // Call the debounced function with the latest value
    handler(value);

    // Cleanup function to cancel debounce on component unmount or when value/delay/options change
    return () => {
      handler.cancel();
    };
  }, [value, delay, options]);

  return debouncedValue;
}

export default useDebounce;

//Dependencies
import {
  useMemo,
  useEffect
} from 'react';
import isEqual from 'lodash.isequal';

//Chakra
import {
  Text,
  Flex,
} from '@chakra-ui/react';
import { ewavesConfig } from '../config/theme-config';

//Components
import {
  // ChartTags,
} from '../components';

//Providers
import { useWaveFinder } from '../providers/wave-finder';
import { useChart } from '../providers/chart';

//Helpers
import { toTitleCase } from '../helpers/utils'; 


export default function ChartFooter() {
  const {activeMarket: market, bchart} = useChart();
  const {sortBy, queryInfo, wfActive} = useWaveFinder();

  const currentWave = useMemo(() => {
    let curWave;

    const waves = market?.waves;

    if(wfActive && waves) {
      curWave = waves[0];
    } else {
      curWave = bchart;
    }

    return curWave;
  }, [wfActive, market, bchart]);

  const sortInfo = useMemo(() => {
    if(currentWave) {
      const value = parseInt(currentWave[sortBy]);
      let units = queryInfo?.attributes[sortBy].units;

      return (`${toTitleCase(sortBy)}: ${Math.round(value)} ${units}`);
    } else {
      return null;
    }
  }, [queryInfo, currentWave, sortBy ]);

  const defaultSortInfo = useMemo(() => {
      if(queryInfo && currentWave) {
        let units = queryInfo?.attributes['elliotticity'].units;
        return (`${toTitleCase('Elliotticity')}: ${Math.round(currentWave?.elliotticity)} ${units}`);
      }
  }, [queryInfo, currentWave]);

  return (
    <Flex className={'chart-footer'} rowGap={'3px'} lineHeight={1} flexDir={'column'} alignItems={'flex-end'} justifyContent={'center'} p={'xs'} width={'100%'} minH={ewavesConfig.chartFooterMinHeight+"px"}>
      {sortInfo
        ?
        <Text fontSize={'sm'} fontWeight={'700'}>{sortInfo}</Text>
        :
        <Text fontSize={'sm'} fontWeight={'700'}>{defaultSortInfo}</Text>
      }
      {sortBy !== 'elliotticity' && sortInfo !== null &&
        <Text fontSize={'xs'} fontWeight={'400'}>({defaultSortInfo})</Text>
      }
    </Flex>
  )
}
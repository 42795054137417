//Dependencies
import {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo
} from "react";
import debounce from "lodash.debounce";
import difference from "lodash.difference";
import { useNavigate, useLocation } from "react-router-dom";

//Chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Divider,
  Box,
  Button,
  Heading,
  VStack,
  IconButton,
  Select,
  HStack,
  Switch,
  Text,
  useToken,
  useDisclosure
} from "@chakra-ui/react";

// Providers
import { useUser } from "../../providers/user";
import { useView } from "../../providers/view";
import { useBuilder } from "../../providers/builder";
import { useSetups } from "../../providers/setups";

// Helpers
import { toTitleCase } from "../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ContextAttributes from "./context-attributes";
import SetupsSelector from "./setups-selector";
import { useWaveFinder } from "../../providers/wave-finder";


// Sets up initial state of query and formats query info data to be handled by children components. Holds state and elements for creating multiple context waves which make up the query
const SetupsBuilder = () => {
  const {user} = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isClear, setIsClear] = useState(false);
  const [isRestore, setIsRestore] = useState(false);

  const {updateFBUserPref} = useUser();
  const {wfActive, setWfActive, setIsLoadingWaves} = useWaveFinder();
  const {setups, setIsModifiedSetup} = useSetups();
  const {builderState, contextWaves, actions} = useBuilder();
  const [toggle, setToggle] = useState(wfActive);

  const savedContexts = useMemo(() => {
    if(builderState?.setup && builderState.setup.length) {
      return [...builderState.setup].map(item => item.name)
    }
  }, [builderState?.setup]);
  const [selectedContexts, setSelectedContexts] = useState(savedContexts ? savedContexts : []);
  const availableContexts = useMemo(() => difference(contextWaves, selectedContexts), [contextWaves, selectedContexts]);

  const cancelRef = useRef();

  const [gray300] = useToken(
    "colors",
    ["gray.300"]
  );

  const borderBefore = {
    position: 'absolute',
    background: 'gray.300',
    top: '1rem',
    left: '-2px',
    width: '2px',
    height: 'calc(100% - 1.625rem)',
  }

  // Create new setup and auto add a new context for "wave"
  const handleNewSetup = () => {
    setSelectedContexts([]); // Ensure all contexts are released
  }

  // Updates the specific empty context to have a name and show the context attributes component instead. All selected context(wave) values are excluded from the select list
  const handleUpdateContextType = (id, value) => {
    actions.updateContext(id, value);
    setSelectedContexts([...selectedContexts, value]);
  }

  // Add empty context placeholder
  const handleAddContext = () => {
    actions.addContext();
  }

  // Delete a context and update selected state array
  const handleRemoveContext = (id, name) => {
    actions.deleteContext(id);
    setSelectedContexts(selectedContexts.filter(select => select !== name));
  }

  const handleToggle = (e) => {
    setIsLoadingWaves(true);
    const enabled = e.target.checked;
    if(!enabled) {
      setWfActive(false);
      setToggle(false);

      updateFBUserPref.queryToggle(false);
    } else {
      setWfActive(true);
      setToggle(true);
      
      updateFBUserPref.queryToggle(true);
    }
  }

  // Restores current setup back to original loaded state
  const handleRestorePrompt = () => {
    onOpen();
    setIsRestore(true);
  }
  
  const handleRestore = useCallback(() => {
    if(user?.querySetup && setups) {
      const copySetups = structuredClone(setups);
      const savedSetup = copySetups.find((setup) => setup.name === user?.querySetup.name);

      actions.replaceBuilderState(savedSetup);
      setIsModifiedSetup(false);
      onClose();
    }
  }, [actions, onClose, setIsModifiedSetup, setups, user?.querySetup]);

  // Turns current setup into an empty query
  const handleClearPrompt = () => {
    onOpen();
    setIsClear(true);
  }
  
  const handleClear = () => {
    actions.clearBuilderState(user?.querySetup.name); // Clears everything from setup except the name
    setSelectedContexts([]); // Ensure all contexts are released
    onClose();
  }

  const handleCancel = () => {
    onClose();
  }

  useEffect(() => {
    if(!isOpen) {
      setIsRestore(false);
      setIsClear(false);
    }
  }, [isOpen]);

  // Update method for saving toggle state to Firebase
  // useEffect(() => {
  //   updateFBUserPref.queryToggle(toggle);

  //   console.log('Toggle', toggle);
  // }, [toggle, updateFBUserPref]);

  const debouncedHandleToggle = debounce((e) => handleToggle(e), 500, {leading: true});

  return (
    <VStack>
      <VStack alignItems={'stretch'} w={'100%'}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Heading size={'md'}>Wave Finder</Heading>
          <Switch className={'query-toggle'} colorScheme={'green'} isChecked={toggle} onChange={debouncedHandleToggle} />
        </HStack>
        <Divider />
      </VStack>
      <Box pos={'relative'} mb={'md'} display={'flex'} flexDir={'column'} zIndex={'docked'} w={'100%'}>
        <VStack alignItems={'flex-start'} spacing={2} w={'100%'}  sx={!wfActive ? {cursor: 'not-allowed'} : {}}>
          <VStack className={'context-wrapper'} position={'relative'} alignItems={'flex-start'} width={'100%'} spacing={'sm'} sx={!wfActive ? {pointerEvents: 'none', opacity: '0.5'} : {}}>
            {setups?.length && <SetupsSelector handleNewSetup={handleNewSetup} /> }
            <VStack w={'100%'}>
              <Heading w={'100%'} size={'xs'} color={'gray.600'} mb={'2'}>Wave Query</Heading>
              {builderState && builderState?.setup?.map((context, i) => (
                <Box
                  key={context.name+i}
                  width={'100%'}
                  position={'relative'}
                  pl={'xs'}
                  backgroundImage={`linear-gradient(${gray300}, ${gray300}), linear-gradient(${gray300}, ${gray300})`}
                  backgroundRepeat={'no-repeat'}
                  backgroundSize={'10px 2px'}
                  backgroundPosition={'0% calc(0% + 1rem), 0% calc(100% - (1.5rem / 2) + 2px)'}
                >
                  <Box sx={borderBefore} />
                  <>
                    <HStack position={'relative'} justifyContent={'flex-start'} mb={'xs'} py={'xs'} pl={'sm'} pr={'md'} width={'fit-content'} backgroundColor={'gray.200'} color={'primary'} borderRadius={'sm'}>
                      <Heading size={'sm'}>{context.name ? toTitleCase(context.name) : 'New Context'}</Heading>
                      <IconButton position={'absolute'} transform={'translate(50%, -50%)'} top={'50%'} right={0} aria-label='Remove Context' borderRadius={'full'} size={'xs'} variant={'outline'} colorScheme={'red'} backgroundColor={'white'}
                        icon={<FontAwesomeIcon icon="fa-solid fa-times" />}
                        onClick={() => handleRemoveContext(context.id, context.name)}
                      />
                    </HStack>
                    <>
                      {context?.name
                        ? 
                          <ContextAttributes key={context.id+i} id={context.id} state={builderState.setup} />
                        :
                          <Select size={'standard'} variant={'lightPrimary'} placeholder='Select Wave' onChange={(e) => {handleUpdateContextType(context.id, e.target.value)}}>
                            {availableContexts?.map((context, key) =>
                              <option key={context+key} value={context}>{toTitleCase(context)}</option>
                            )}
                          </Select>
                      }
                    </>
                  </>
                </Box>
              ))}
            </VStack>
            <Button variant={'primary'} size={'sm'} color={'gray.500'} borderColor={'gray.300'} _hover={{bgColor:'primary', borderColor: 'primary', color: 'white'}}
              leftIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
              onClick={handleAddContext}
            >Add Context Wave</Button>
            <HStack justifyContent={'flex-end'} w={'100%'}>
              <Button alignSelf={'end'} size={'xs'} mt={'xs'} colorScheme={'gray'} rightIcon={<FontAwesomeIcon icon="fa-solid fa-rotate-left" />} onClick={handleRestorePrompt}>Restore</Button>
              <Button alignSelf={'end'} size={'xs'} mt={'xs'} colorScheme={'red'} rightIcon={<FontAwesomeIcon icon="fa-solid fa-trash-xmark" />} onClick={handleClearPrompt}>Clear</Button>
            </HStack>
          </VStack>
        </VStack>
        
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {isClear && 
                  <Text>Clear Setup</Text>
                }
                {isRestore && 
                  <Text>Restore Setup</Text>
                }
              </AlertDialogHeader>

              <AlertDialogBody bgColor={'transparent'}>
                {isClear && 
                  <Text>Are you sure? This action will completely clear the current setup query.</Text>
                }
                {isRestore && 
                  <Text>Are you sure? This action will restore back to the last saved state of the current setup.</Text>
                }
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancel}>
                  Cancel
                </Button>
                {isClear && 
                  <Button colorScheme='red' onClick={handleClear} ml={3}>Clear</Button>
                }
                {isRestore && 
                  <Button colorScheme='red' onClick={handleRestore} ml={3}>Restore</Button>
                }
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </VStack>
  )
}

export default SetupsBuilder;
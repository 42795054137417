//Dependencies
import {
  useEffect,
  useState,
  useCallback,
  useMemo
} from "react";
import debounce from "lodash.debounce";

//Chakra
import {
  Heading,
  VStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
} from "@chakra-ui/react";

//Providers
import { useBuilder } from "../../providers/builder";

//Helpers
import { toTitleCase } from "../../helpers/utils";


//Range Input represents 2nd type of attribute and has a min and max range and displays the type of units being passed. Can potentially have an infinite min or max range.
const RangeInput = ({contextId, id, name, fields, values}) => {
  const {actions} = useBuilder();

  //Format fields to find only options for this name
  const range = useMemo(() => [...fields].filter(field => field.name === name).pop(), [name, fields]);

  //State for min and max values (Values are null for new attributes.)
  const [min, setMin] = useState(Number.isInteger(values?.min) ? values.min : range.min);
  const [max, setMax] = useState(Number.isInteger(values?.max) ? values.max : range.max);

  const debouncedUpdateAttribute = useMemo(() => 
    debounce((min, max) => {
      actions.updateAttribute(contextId, id, name, { min, max });
    }, 250), // Adjust the debounce delay as needed
    [actions, contextId, id, name]);

  //Debounced inputs
  const handleChangeMin = (valueAsNumber) => {
    setMin(valueAsNumber);
    debouncedUpdateAttribute(parseFloat(valueAsNumber), parseFloat(max));
  };
  const handleChangeMax = (valueAsNumber) => {
    setMax(valueAsNumber);
    debouncedUpdateAttribute(parseFloat(min), parseFloat(valueAsNumber));
  };

  useEffect(() => {
    if (id && name && !values) {
      actions.updateAttribute(contextId, id, name, { min: range.min, max: range.max });
    }
  }, [actions, contextId, id, name, range.min, range.max, values]);

  useEffect(() => {
    if(values) {
      setMin(values.min);
      setMax(values.max);
    }
  }, [values])

  return (
    <VStack alignItems={'flex-start'} spacing={1}>
      <Heading size={'xs'} color={'gray.600'} lineHeight={'inherit'}>{toTitleCase(name)}:</Heading>
      <HStack>
        <NumberInput size='xs' w={'45%'} defaultValue={min} value={min} min={range.min} max={range.max} onChange={handleChangeMin}>
          <NumberInputField textAlign={'center'} background={'white'}/>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text w={'10%'} textAlign={'center'}>to</Text>
        <NumberInput size='xs' w={'45%'} defaultValue={max} value={max} min={range.min} max={range.max} onChange={handleChangeMax}>
          <NumberInputField textAlign={'center'} background={'white'} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </HStack>
      <Text fontSize={'xs'} fontWeight={'700'} color={'gray.600'}>in {range.units}</Text>
    </VStack>
  )
}

export default RangeInput;
// Dependencies
import { useEffect, useState, useRef, useContext, useMemo, createContext } from 'react';
import { onSnapshot, doc } from "firebase/firestore";
import isEqual from 'lodash.isequal';

// Providers
import { convertQueryToBuilderState } from '../builder';

//Helpers
import { db, setFBUser } from '../../helpers/firebase';
import { useAuthentication } from '../authentication';
import { useUser } from '../user';


const SetupsContext = createContext();

function SetupsProvider(props) {
  const {authentication} = useAuthentication();
  const {user} = useUser();
  const [userSetups, setUserSetups] = useState(user?.savedUserSetups ? user?.savedUserSetups : []);
  const [builtinSetups, setBuiltinSetups] = useState([]);
  const [currentSetup, setCurrentSetup] = useState(user?.querySetup);
  const [isModifiedSetup, setIsModifiedSetup] = useState(false);
  const [setups, setSetups] = useState([]);

  const prevUserSetups = useRef(user?.savedUserSetups);

  useEffect(() => {
    if(userSetups.length || builtinSetups.length) {
      const copyUserSetups = structuredClone(userSetups).map(setup => ({...setup, type: 'user'}));
      const copyBuiltinSetups = structuredClone(builtinSetups).map(setup => ({...setup, type: 'builtin'}));

      // Update setups once both userSetups and builtinSetups are populated
      setSetups([...copyUserSetups, ...copyBuiltinSetups]);
    }
  }, [userSetups, builtinSetups]);

  // This keeps Firebase sync'd with the UI
  useEffect(() => {
    async function updateFBUserSetups() {
      try {
        setFBUser(authentication?.uid, { savedUserSetups: userSetups });
      } catch (e) {
        console.error(e);
      }
    }

    if(!isEqual(prevUserSetups.current, userSetups)) {
      console.log('User setups saving As...');
      updateFBUserSetups().then(() => {
        console.log('Updated to Firebase!')
        prevUserSetups.current = userSetups;
      });
    }
  }, [userSetups, authentication?.uid, user]);

  // We grab these from Firebase because they aren't kept in the user data
  useEffect(() => {
    const unsubscribeBuiltinSetups = onSnapshot(doc(db, "site", "config"), (snapshot) => {
      const fbTemplates = JSON.parse(snapshot.data()?.templates || "[]");
      const builtins = fbTemplates.map(template => convertQueryToBuilderState(template));
      setBuiltinSetups(builtins);
    });

    return () => {
      unsubscribeBuiltinSetups();
    };
  }, [authentication]);

  // Set the current setup when the user's querySetup changes and determine what type of setup it is
  useEffect(() => {
    if(user?.querySetup !== null) {
      const isBuiltin = setups.some(setup => setup?.name === user?.querySetup?.name && setup?.type === 'builtin');

      setCurrentSetup({
        ...user?.querySetup,
        type: isBuiltin ? 'builtin' : 'user'
      });
    }
  }, [user?.querySetup, setups]);

  const value = useMemo(() => ({
    setups,
    userSetups, setUserSetups,
    builtinSetups,
    currentSetup, setCurrentSetup,
    isModifiedSetup, setIsModifiedSetup
  }), [setups, userSetups, builtinSetups, currentSetup, isModifiedSetup]);

  return <SetupsContext.Provider value={value} {...props} />;
}

function useSetups() {
  const context = useContext(SetupsContext);

  if (!context) {
    throw new Error(`useQuery must be used within a SetupsProvider`);
  }

  return context;
}

export {SetupsProvider, useSetups};
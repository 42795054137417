//Dependencies
import {
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import difference from "lodash.difference";

//Chakra
import {
  Button,
  Heading,
  VStack,
  Select,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

//Providers
import { useBuilder } from "../../providers/builder";

//Helpers
import { toTitleCase } from "../../helpers/utils";


//List Input represents 1st type of attribute and has an array of string values. Can have multiple values selected at once, but only one of each given value.
const ListInput = ({contextId, id, name, fields, values}) => {
  const {actions} = useBuilder();
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(values ? values : []);

  //Format fields to find only options for this name
  const options = useMemo(() => {
    const field = [...fields].filter(field => field.name === name).pop();
    return field.options;
  }, [name, fields]);

  //Available options are only the ones not currently selected
  const availableOptions = useMemo(() => {
    return difference(options, selected);
  }, [options, selected]);

  //Update component state and query state when new values selected or removed
  const handleSelectValues = useCallback((e) => {
    const newSelected = [...selected, e.target.value];

    actions.updateAttribute(contextId, id, null, newSelected);
    setSelected(newSelected);

    //Ensures that the select field shows "Add More Types".
    //Otherwise the select field would show last type selected. (Should probably use different DOM / elements structure in future)
    setValue('');
  }, [actions, contextId, selected, id]);

  const handleDeleteValues = (item) => {
    const newSelected = selected.filter(select => select !== item);

    actions.updateAttribute(contextId, id, null, newSelected);
    setSelected(newSelected);
  }

  // Sync selected state with values prop when it changes
  useEffect(() => {
    if(values) {
      setSelected(values);
    }
  }, [values]);

  return (
    <VStack alignItems={'flex-start'} w={'100%'}>
      {selected?.length &&
        <Wrap>
          <Heading size={'xs'} color={'gray.600'} lineHeight={'inherit'}>{toTitleCase(name)}s:</Heading>
          {selected.map((item, key) => (
            <WrapItem key={item+key}>
              <Button size={'xs'} bgColor={'primary'} color={'white'} _hover={{bgColor: 'red'}} onClick={() => handleDeleteValues(item)}>{item}</Button>
            </WrapItem>
          ))}
        </Wrap>
      }
      <Select
        sx={{
          width: '100%',
          fontWeight: 'medium',
          overflow: 'hidden',
          whiteSpace: 'pre',
          textOverflow: 'ellipsis',
        }}
        variant={'lightPrimary'} size={selected?.length > 0 ? 'xs' : 'sm'}
        placeholder={`Add ${selected?.length > 0 ? 'More' : ''} ${toTitleCase(name)}s`} 
        value={value} 
        onChange={handleSelectValues}>
        {availableOptions.map((option, key) =>
          <option key={key} value={option}>{option}</option>
        )}
      </Select>
    </VStack>
  )
}

export default ListInput;